import React from 'react';
import Layout from '../components/layout';

import Divider from '../components/Divider';
import Seo from '../components/seo'
import Screenshot from "../images/case-study.png"
import Blob from "../images/blobs/orange.svg"
import downloadFile from "../files/Cahoot_Case_Study.pdf"

export default function CaseStudy() {
    return (
        <Layout>
            <Seo title="Case Study" />
            <div id="about" className="relative">
                <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-18 flex content-center">
                    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
                        <h1 className="text-3xl lg:text-4xl font-extrabold text-cahoot-blue-med leading-5 tracking-tight sm:text-4xl font-header text-center">
                            Case Study
                        </h1>
                    </div>
                </div>
                <div className="md:-mt-12 lg:-mt-36">
                    <Divider color="lightGray1" />
                </div>
                {/* Content start */}
                <div className="bg-cahoot-white lg:-mt-10">
                    <div className="px-6 lg:mx-auto lg:max-w-3xl font-text pb-12">
                        <div className='text-xl text-center font-header text-cahoot-blue-med lg:leading-9'>Helping one client increase their online presence and reach a new audience with Cahoot</div>
                        <p className="mt-10">
                            Our client is a large fraternal organization with over 3200 members. While during their early years they had no trouble attracting members and increasing engagement, over time, they recognized a steady decline in new memberships. Their primary mode of information sharing had been a monthly newsletter, on-premise flyers, and word-of-mouth advertising by existing members. This lack of active interaction led to disconnected engagement amongst members and made the group vulnerable to sharing misinformation. Some of the client’s top goals were:
                        </p>
                        <p>
                            <ul>
                                <li>To improve organizational engagement through better communication about group events and activities.</li>
                                <li>To create awareness of the organization and its mission with a new audience.</li>
                                <li>To increase organization membership rates by attracting younger and technologically-oriented individuals.</li>
                            </ul>
                        </p>
                        <p>
                            At Apotheosis Technologies we looked to build a more cohesive and responsive channel of communication for the client. This channel was designed to allow the organization to easily share accurate information about their various events to prospective and existing members.
                        </p>
                        <p>
                            The solution we designed was the Cahoot Digital Expeience Platform – a content and communication platform designed to help groups manage their online interactions seamlessly and increase their digital presence.  With Cahoot, our client was able to create a library of content to inform existing members of upcoming events and news articles. The platform streamlined and automated the publishing of information on their website, ensuring it was always up-to-date. Over a few weeks, Cahoot reduced the client’s dependency on offline communication methods such as newsletters and flyers. This solution also integrates with various social media channels which allowed the client to quickly publish their content on Facebook and helped them engage a younger and techno-centric audience to attract prospective members. The platform’s push notification feature ensured users of the Cahoot Mobile App received the client’s updates on time, every time.
                        </p>
                        <p>
                            Based on the feedback we received from our client, they were able to increase their online footprint and membership opportunities with the help of the Cahoot. The platform also reduced miscommunications and improved the quality of responsive engagement by allowing the client’s core team to control the communication being shared with existing and prospective members.
                        </p>
                    </div>

                    {/* Case study section */}
                    <div className="relative bg-cahoot-gray-med">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 md:grid md:grid-cols-2 lg:gap-24 md:items-start">
                            <div className="relative sm:py-16 md:py-0">
                                <div
                                    aria-hidden="true"
                                    className="hidden lg:block  lg:inset-y-0 lg:right-0 lg:w-screen"
                                >
                                    <img src={Blob} alt="Decorative blobs" className="absolute md:-left-8 lg:left-0 inset-y-0 w-full" />

                                </div>
                                {/* Small: Content area */}
                                <div className="text-center text-cahoot-blue-dark relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 md:hidden">
                                    <div className="pt-12 sm:pt-16 lg:pt-20">
                                        <h2 className="text-3xl font-header  font-extrabold tracking-tight sm:text-4xl">
                                            Download our Case Study
                                        </h2>
                                        <div className="mt-6 space-y-6 font-text">
                                            <p className="text-lg">
                                                <span className="font-header">Cahoot</span> is enterprise-grade software built to make the job
                                                of managing content simple for organizations. With <span className="font-header">Cahoot</span>,
                                                you can manage your content in a single place without the
                                                need to juggle multiple apps.
                                            </p>
                                            <div className="flex flex-row pt-10 justify-center">

                                                <div className="flex mt-4">
                                                    <a
                                                        href={downloadFile}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        alt="Download our case study"
                                                        className="block w-full rounded-md font-text border border-transparent px-5 py-3 bg-cahoot-blue-med text-base font-bold text-cahoot-white hover:text-cahoot-white shadow hover:bg-cahoot-blue-dark focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med sm:px-10"
                                                    >
                                                        Download your copy
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Small: Image*/}
                                <div className="md:hidden relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:p-20">
                                    <div className="relative h-96 pt-10">
                                        <div className="absolute right-16 pl-16">
                                            <img src={Screenshot} alt="Case study screenshot" className='object-contain lg:h-[25rem] rounded-2xl shadow-xl md:-rotate-6' />
                                        </div>
                                    </div>
                                </div>
                                {/* Medium +: Image*/}
                                <div className="hidden md:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:p-20">
                                    <div className="relative h-96">
                                        <div className="absolute md:right-8 md:top-16 lg:top-0 lg:right-28">
                                            <img src={Screenshot} alt="Case study screenshot" className='object-contain lg:h-[25rem] rounded-2xl shadow-xl md:-rotate-6' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Medium +: Content area */}
                            <div className="hidden md:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                                <div className="pt-12 sm:pt-16 lg:pt-20">
                                    <h2 className="text-2xl lg:text-3xl font-header text-cahoot-blue-dark font-extrabold tracking-tight sm:text-4xl">
                                        Download our Case Study
                                    </h2>
                                    <div className="mt-6 text-cahoot-blue-dark font-text space-y-6 leading-normal">
                                        <p className="lg:text-xl">
                                            <span className="font-header">Cahoot</span> is enterprise-grade software built to make the job of
                                            managing content simple for organizations. With <span className="font-header">Cahoot</span>, you
                                            can manage your content in a single place without the need
                                            to juggle multiple apps.
                                        </p>
                                        <div className="flex flex-row pb-10 lg:pb-0 lg:pt-10 justify-center">

                                            <div className="flex mt-4">
                                                <a
                                                    href={downloadFile}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    alt="Download our case study"
                                                    className="block w-full rounded-md font-text border border-transparent px-5 py-3 bg-cahoot-blue-med text-base font-bold text-cahoot-white hover:text-cahoot-white shadow hover:bg-cahoot-blue-dark focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cahoot-blue-med sm:px-10"
                                                >
                                                    Download your copy
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}